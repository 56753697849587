import React, { Component } from 'react';
import { withRouter, BrowserRouter, useLocation } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import toast, { Toaster } from 'react-hot-toast';
import HelperRoutes from '../helper';
import SocketContext from '../content/socketContent';
import { socket } from "../config/socketConnectivity";
import { useState } from 'react';
import { useEffect } from 'react';

class App extends Component {
  state = {}
  componentDidMount() {
    this.onRouteChanged();
  }
  render() {

    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : '';
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : '';
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : '';

    return (
      <>
        <div><Toaster /></div>
        <BrowserRouter>
        {/* <SocketContext.Provider value={{ socket  }}>
      <HelperRoutes /> */}
          <div className="container-scroller">
            {sidebarComponent}
            <div className="container-fluid page-body-wrapper">
              {navbarComponent}
              <div className="main-panel">
                <div className="content-wrapper">
                  <AppRoutes />
                </div>
                {footerComponent}
              </div>
            </div>

          </div>
          {/* </SocketContext.Provider> */}
        </BrowserRouter></>

    );
  }


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    const { i18n } = this.props;
    const body = document.querySelector('body');
    if (this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
      i18n.changeLanguage('en');
    }
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ['/login', '/register', '/forgot-password','/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      console.log("this.props.location.pathname", this.props.location.pathname, fullPageLayoutRoutes[i], this.props.location.pathname === fullPageLayoutRoutes[i])
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true
        })
        document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
        break;
      } else {
        this.setState({
          isFullPageLayout: false
        })
        document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
      }
    }
  }

}

export default withTranslation()(withRouter(App));

// const App = () => {
//   const [isFullPageLayout, setisFullPageLayout] = useState('')
//   const location = useLocation()
//   useEffect(() => {
//     // if (location !== prevProps.location) {
//     onRouteChanged();
//     // }
//     // onRouteChanged()
//   }, [location])
//   let navbarComponent = !isFullPageLayout ? <Navbar /> : '';
//   let sidebarComponent = !isFullPageLayout ? <Sidebar /> : '';
//   let footerComponent = !isFullPageLayout ? <Footer /> : '';
//   useEffect(()=>{
//      navbarComponent = !isFullPageLayout ? <Navbar /> : '';
//      sidebarComponent = !isFullPageLayout ? <Sidebar /> : '';
//      footerComponent = !isFullPageLayout ? <Footer /> : '';
//   },[isFullPageLayout])
//   const onRouteChanged = () => {
//     console.log("ROUTE CHANGED");
//     // const { i18n } = this.props;
//     const body = document.querySelector('body');
//     // if (location.pathname === '/layout/RtlLayout') {
//     //   body.classList.add('rtl');
//     //   i18n.changeLanguage('ar');
//     // }
//     // else {
//     //   body.classList.remove('rtl')
//     //   i18n.changeLanguage('en');
//     // }
//     window.scrollTo(0, 0);
//     const fullPageLayoutRoutes = ['/login', '/register', '/forgot-password', '/user-pages/login-1', '/user-pages/login-2', '/user-pages/register-1', '/user-pages/register-2', '/user-pages/lockscreen', '/error-pages/error-404', '/error-pages/error-500', '/general-pages/landing-page'];
//     for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
//       if (location.pathname === fullPageLayoutRoutes[i]) {

//         setisFullPageLayout(true)

//         document.querySelector('.page-body-wrapper').classList.add('full-page-wrapper');
//         break;
//       } else {

//         setisFullPageLayout(true)

//         document.querySelector('.page-body-wrapper').classList.remove('full-page-wrapper');
//       }
//     }
//   }
  
//   return (
//     <>
//       <div><Toaster /></div>
//       <BrowserRouter>
//         {/* <SocketContext.Provider value={{ socket  }}>
//           <HelperRoutes /> */}
//         <div className="container-scroller">
//           {sidebarComponent}
//           <div className="container-fluid page-body-wrapper">
//             {navbarComponent}
//             <div className="main-panel">
//               <div className="content-wrapper">
//                 <AppRoutes />
//               </div>
//               {footerComponent}
//             </div>
//           </div>

//         </div>
//         {/* </SocketContext.Provider> */}
//       </BrowserRouter>
//     </>
//   )
// }

// export default App