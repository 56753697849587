import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FaUserCircle } from "react-icons/fa";
import { MdSpaceDashboard } from "react-icons/md";
import { BiSolidCoinStack } from "react-icons/bi";
import { IoSettingsSharp } from "react-icons/io5";
import { RiFileSearchFill } from "react-icons/ri";


class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    console.log("this.props.location !== prevProps.location",this.props.location !== prevProps.location)
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    // const dropdownPaths = [
    //   { path: "/apps", state: "appsMenuOpen" },
    //   { path: "/basic-ui", state: "basicUiMenuOpen" },
    //   { path: "/form-elements", state: "formElementsMenuOpen" },
    //   { path: "/tables", state: "tablesMenuOpen" },
    //   { path: "/icons", state: "iconsMenuOpen" },
    //   { path: "/charts", state: "chartsMenuOpen" },
    //   { path: "/user-pages", state: "userPagesMenuOpen" },
    //   { path: "/error-pages", state: "errorPagesMenuOpen" },
    // ];

    // dropdownPaths.forEach((obj) => {
    //   if (this.isPathActive(obj.path)) {
    //     this.setState({ [obj.state]: true });
    //   }
    // });
  }

  render() {
    console.log(this.props.location,this?.props?.location.pathname,"this.props.location")
    let path  = this.props.location.pathname
    
    return (
      <nav className={((path == '/login') || ((path.split("/")[1]).includes("reset-password")) ||  ((path.split("/")[1]).includes("forgot-password"))) ?'sidebarnone' :"sidebar sidebar-offcanvas"} id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link to="/dashboard" classList="text-decoration-none">
            <a className="sidebar-brand brand-logo d-flex align-items-center">
              <img
                src={require("../../assets/images/HeaderLogo.png")}
                alt="logo"
                className="logo img_fluid"
              />
              <span className="logo_txt pl-2">COINPAYMATE</span>
            </a>
          </Link>
          <Link to="/dashboard" classList="text-decoration-none">
            <a className="sidebar-brand brand-logo-mini">
              <img
                src={require("../../assets/images/HeaderLogo.png")}
                alt="logo"
                className="logo img_fluid hidden_sidebar_img"
              />
            </a>
          </Link>
        </div>
        <ul className="nav">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img
                    className="img-xs rounded-circle "
                    src={require("../../assets/images/faces/face15.png")}
                    alt="profile"
                  />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0">
                    <Trans>Henry Klein</Trans>
                  </h5>
                  <span>
                    <Trans>Admin</Trans>
                  </span>
                </div>
              </div>
            </div>
          </li>
          <li
            className={
              this.isPathActive("/dashboard")
                ? "nav-item menu-items active mt-4"
                : "nav-item menu-items mt-4"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <MdSpaceDashboard/>
              </span>
              <span className="menu-title">
                <Trans>Dashboard</Trans>
              </span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/users")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/users">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <FaUserCircle/>
              </span>
              <span className="menu-title">
                <Trans>Users</Trans>
              </span>
            </Link>
            
          </li>
          <li
            className={
              this.isPathActive("/currency") ||
              this.isPathActive("/add-currency") ||
              this.isPathActive("/edit-currency")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/currency">
              <span className="menu-icon">
                {/* <i className="mdi mdi-currency-btc"></i> */}
                <BiSolidCoinStack/>
              </span>
              <span className="menu-title">
                <Trans>Currency</Trans>
              </span>
            </Link>
          </li>
          {/* <li
            className={
              this.isPathActive("/support-ticket")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/support-ticket">
              <span className="menu-icon">
                <i className="mdi mdi-settings-box"></i>
              </span>
              <span className="menu-title">
                <Trans>Support Ticket</Trans>
              </span>
            </Link>
          </li> */}

          <li
            className={
              this.isPathActive("/fee-settings")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/fee-settings">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <IoSettingsSharp/>
              </span>
              <span className="menu-title">
                <Trans>Settings</Trans>
              </span>
            </Link>
            
          </li>
          <li
            className={
              this.isPathActive("/kyc")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/kyc">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <RiFileSearchFill/>
              </span>
              <span className="menu-title">
                <Trans>KYC</Trans>
              </span>
            </Link>
            
          </li>

          <li
            className={
              this.isPathActive("/admincommission")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/admincommission">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <RiFileSearchFill/>
              </span>
              <span className="menu-title">
                <Trans>Admin Commission</Trans>
              </span>
            </Link>
            
          </li>
          <li
            className={
              this.isPathActive("/usercommission")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/usercommission">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <RiFileSearchFill/>
              </span>
              <span className="menu-title">
                <Trans>User Commission</Trans>
              </span>
            </Link>
            
          </li>
          <li
            className={
              this.isPathActive("/userfeedbacks")
                ? "nav-item menu-items active mt-3"
                : "nav-item menu-items mt-2"
            }
          >
            <Link className="nav-link" to="/userfeedbacks">
              <span className="menu-icon">
                {/* <i className="mdi mdi-speedometer"></i> */}
                <RiFileSearchFill/>
              </span>
              <span className="menu-title">
                <Trans>User Feedbacks</Trans>
              </span>
            </Link>
            
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
