export const  style = {
      
    minWidth: '300px',
    minHeight: '55px',
    border: '2px solid #fff',
    padding: '18px',
    color: '#fff',
    fontWeight:"800",
    background: '#007bff',
    zIndex:"99999"
    
}

export const iconTheme = {
    primary: '#fff',
    secondary: '#000',
}

export const  position= {
    position: "bottom-center"
}


export const  zindex= {
    zIndex: "99999"
}

export const duration = {
    duration : 4000
}