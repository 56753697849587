import io from 'socket.io-client';
import { ADMINURL, BACKURL } from './env';


const socket = io(BACKURL);

socket.on('connection', function () {
    console.log('Client Connected');
    const token = sessionStorage.getItem("accessToken");
    console.log("tokendata", token)
    if (token) {
        createSocketUser()

    }
});


const createSocketUser = () => {
    console.log("emit_frontend")
    // socket.emit('LOGINUSER', "venkatesh")
}


socket.on("ADMINFORCELOGOUT", (data) => {

    try {
        if (data) {
            console.log("ADMINFORCELOGOUT", data)
        }
    } catch (e) {
        console.log("socketError : ", e);
        localStorage.clear();
        window.location.href = ADMINURL;
    }
});

export {
    socket,
    createSocketUser
}
