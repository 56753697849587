import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import toast, { Toaster } from 'react-hot-toast';
const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Currency = lazy(() => import("./currency/Currency"));
const AddCurrency = lazy(() => import("./currency/AddCurrency"));
const EditCurrency = lazy(() => import("./currency/EditCurrency"));
const MyProfile = lazy(() => import("./settings/MyProfile"));
const EditSettings = lazy(() => import("./settings/EditSettings"));
const Resetpasswords = lazy(() => import("./settings/resetpassword"));
const Notifications = lazy(() => import("./notification/Notifications"));
const SupportTicket = lazy(() => import("./support-ticket/SupportTicket"));
const Settings = lazy(() => import("./feesetting/Setting"));
const AddSettings = lazy(() => import("./feesetting/AddSetting"));
const EditFeeSetting = lazy(() => import("./feesetting/EditSetting"));

const ViewKyc = lazy(() => import("./Kyc/viewkys"));

const Login = lazy(() => import("./user-pages/Login"));
const ResetPassword = lazy(() => import("./user-pages/AdminResetpassword"));
const Register = lazy(() => import("./user-pages/Register"));
// const ResetPassword = lazy(() => import("./Reset-password/ResetPassword"));
const ForgotPassword = lazy(() => import("./user-pages/ForgotPassword"));
const adminkyc = lazy(() => import("./Kyc/Kyc"));
const Users = lazy(() => import("./Users/User"));
const Userslist = lazy(() => import("./Users/userlist"));
const UserAddressInfoList = lazy(() => import("./Users/useaddressInfolist"));
const AdminCommission = lazy(() => import("./Commission/AdminCommission"));
const UserCommission = lazy(() => import("./Commission/UserCommission"));
const UserFeedBacks = lazy(() => import("./Feedback/Userfeedbacks"));

class AppRoutes extends Component {
  constructor(props) {
    super();
    this.auth = "red";
  }
  render() {
    // console.log("Propss", this.props);

    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/login" render={() => <Login />} />
          <Route exact path="/dashboard" component={Dashboard} />

          <Route exact path="/currency" component={Currency} />
          <Route exact path="/add-currency" component={AddCurrency} />
          <Route exact path="/edit-currency" component={EditCurrency} />

          <Route exact path="/support-ticket" component={SupportTicket} />

          <Route exact path="/my-profile" component={MyProfile} />
          <Route exact path="/edit-settings" component={EditSettings} />
          <Route exact path="/resetpassowrds" component={Resetpasswords} />
          <Route exact path="/reset-password/:otp" 
          
          component={ResetPassword} 
          />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/notifications" component={Notifications} />
          <Route exact path="/fee-settings" component={Settings} />
          <Route exact path="/edit-feesetting" component={EditFeeSetting} />
          <Route exact path="/viewkyc" component={ViewKyc} />
          <Route exact path="/add-setting" component={AddSettings} />
          <Route exact path="/kyc" component={adminkyc} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/userslist" component={Userslist} />
          <Route exact path="/useraddresslist" component={UserAddressInfoList} />
          <Route exact path="/admincommission" component={AdminCommission} />
          <Route exact path="/usercommission" component={UserCommission} />
          <Route exact path="/userfeedbacks" component={UserFeedBacks}/>
          {/* <Route path="/register" component={Register} /> */}
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
