import axios from 'axios';
import { BACKURL } from '../config/env';
import { position, iconTheme, style } from './UseToast';
import toast from "react-hot-toast";

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const getAuthToken = () => {
    try {
        let accessToken = sessionStorage.getItem("accessToken");

        if (accessToken != '' && accessToken != null && accessToken != undefined) {
            return `Bearer ${accessToken}`
        }
    } catch (e) {
        console.log("getAuthToken_err", e)
    }
}

export const Test = () => {
    axios.defaults.headers.common['Authorization'] = getAuthToken();
}

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const adminloginhooks = async (data) => {
    try {
        
        let respData = await axios.post(BACKURL + "/api/admin/login", data);
       
        if (respData?.data?.success == true) {
            Toast("success", respData?.data?.data)
        } else {
            Toast("error", respData?.data?.data)
        }
        return respData;

    } catch (e) {
        console.log("adminloginhooks_err", e)
    }
}


export const adminaddassethooks = async (data) => {
    try {
        console.log("adminaddassethooks data", data);
        const { assettype, networktype, tokenAddress, name, symbol, decimal, chainId, rpc, network, depositfee, withdrawfee, minimumwithdraw, image, filter, priceAPI,
            accountNo, bankName, holderName, bankCode, country, accesstoken } = data;
        const formData = new FormData();

        formData.append("type", assettype);
        formData.append("name", name);
        formData.append("symbol", symbol);
        formData.append("depositfee", depositfee);
        formData.append("withdrawfee", withdrawfee);
        formData.append("minimumwithdraw", minimumwithdraw);
        formData.append("priceAPI", priceAPI);
        formData.append("filter", "add");
        formData.append("image", image);
        formData.append("accountNo", accountNo);
        formData.append("bankName", bankName);
        formData.append("holderName", holderName);
        formData.append("bankCode", bankCode);
        formData.append("country", country);
        formData.append("accessToken", accesstoken)

        if (assettype == "crypto" || assettype == "token") {
            formData.append("networktype", networktype);
            formData.append("tokenAddress", tokenAddress);
            formData.append("decimal", decimal);
            formData.append("chainId", chainId);
            formData.append("rpc", rpc);
            formData.append("network", network);
        }

        let respData = await axios.post(BACKURL + "/api/admin/addasset", formData);
        console.log('respdafasfasdfasdf', respData)
        if (respData?.data?.success == true) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false) {
            Toast("error", respData?.data?.data)
        }

        return respData;
    } catch (e) {
        console.log("adminaddassets_err", e)
    }
}

export const adminupdateassethooks = async (data) => {
    try {
        const { assettype, networktype, tokenAddress, name, symbol, decimal, chainId, rpc, network, depositfee, withdrawfee, minimumwithdraw, image, filter, priceAPI, file, _id,
            accountNo, bankName, holderName, bankCode, country, accesstoken } = data;
        const formData = new FormData();

        formData.append("type", assettype);
        formData.append("name", name);
        formData.append("symbol", symbol);
        formData.append("depositfee", depositfee);
        formData.append("withdrawfee", withdrawfee);
        formData.append("minimumwithdraw", minimumwithdraw);
        formData.append("priceAPI", priceAPI);
        formData.append("filter", "update");
        formData.append("image", file);
        formData.append("_id", _id);
        formData.append("accountNo", accountNo);
        formData.append("bankName", bankName);
        formData.append("holderName", holderName);
        formData.append("bankCode", bankCode);
        formData.append("country", country);
        formData.append("accessToken", accesstoken);

        if (assettype == "crypto" || assettype == "token") {
            formData.append("networktype", networktype);
            formData.append("tokenAddress", tokenAddress);
            formData.append("decimal", decimal);
            formData.append("chainId", chainId);
            formData.append("rpc", rpc);
            formData.append("network", network);
        }

        let respData = await axios.post(BACKURL + "/api/admin/updateasset", formData);
        if (respData?.data?.success == true) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {

    }
}

export const getallassetdatahooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/admin/getAsset", data);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log("getallassetdatahooks_err", e)
    }
}

export const updateassetenabledhooks = async (data) => {
    try {
        let status = data?.status == "active" ? "Active" : "InActive";
        let respData = await axios.post(BACKURL + "/api/admin/updateassetEnabled", data);

        if (respData?.data?.success == true) {
            Toast("success", `${status} Successfully !!!`)
        } else {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log("updateassetenabledhooks_err", e)
    }
}

export const requestadminemailhooks = async (data) => {
    try {
        const respData = await axios.post(BACKURL + "/api/admin/request/reset", data);
        console.log('requestemailhooks', data, respData?.data?.data);

        if (respData?.data?.success == true) {
            Toast("success", respData?.data?.data)
        } else {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log("requestadminemailhooks_err", e)
    }
}

export const adminresetpasswordhooks = async (data) => {
    try {
        let respData = await axios.post(BACKURL + "/api/admin/resetpassword", data);

        if (respData?.data?.success == true) {
            Toast("success", respData?.data?.data)
        } else {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log("adminresetpasswordhooks_err", e)
    }
}

export const getadmindetailsbyemail = async (email) => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/infobyemail/${email}`);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log('getadmindetailsbyemail_err', e)
    }
}

export const getallsettingDetails = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/setting/info`);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {

    }
}

export const adminaddsettinghooks = async (data) => {
    try {

        let respData = await axios.post(BACKURL + "/api/admin/new/setting", data);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log('adminaddsettinghooks_err', e)
    }
}

export const adminupdatesettinghooks = async (data) => {
    try {

        let respData = await axios.post(BACKURL + "/api/admin/update/setting", data);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log('adminaddsettinghooks_err', e)
    }
}

export const getadmindetailsbytoken = async (token) => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/infobytoken/${token}`);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {
        console.log('getadmindetailsbyemail_err', e)
    }
}




export const Toast = async (filter, message) => {
    try {
        console.log("tilerrrrrr", filter, message)
        if (filter == "success") {
            toast.success(message, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            })
            await sleep(1000)
        } else {
            console.log('tilerrrrrr', filter, message)
            toast.error(message, {
                position: position.position,
                style: style,
                iconTheme: iconTheme,
            })
            await sleep(1000)
        }


    } catch (e) {
        console.log("Toast_err", e)
    }
}



export const getallKycRequestDetails = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/kycrequest`);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {

    }
}

export const getallAdminCommission = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/commission`);

        if (respData?.data?.success == true && respData?.data?.record != undefined) {
            return respData?.data;

        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            return []
        }

    } catch (e) {

    }
}


export const getallUserCommission = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/user/commission`);
        if (respData?.data?.success == true && respData?.data?.record != undefined) {
            return respData?.data;

        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            return []
        }
    } catch (e) {

    }
}





export const getallUserdeatils = async () => {
    try {
        let respData = await axios.get(`${BACKURL}/api/user/data`);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data)
        } else if (respData?.data?.success == false && respData?.data?.data != undefined) {
            Toast("error", respData?.data?.data)
        }
        return respData;
    } catch (e) {

    }
}



export const UseAdminVerifyKycHooks = async (data) => {
    try {
        console.log("verifydatahooks data", data)
        let respData = await axios.post(BACKURL + "/api/admin/verifykycdetails", data);
        if (respData?.data?.success == true) {
            Toast("success", respData?.data?.data)
        } else {
            Toast("error", respData?.data?.data)
        }
        return respData;

    } catch (e) {
        console.log("adminloginhooks_err", e)
    }
}



export const getuserwalletdetails = async (data) => {
    try {

        let respData = await axios.post(
            BACKURL + "/api/User/getUserWalletData",
            data
        );
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data);
        } else if (
            respData?.data?.success == false &&
            respData?.data?.data != undefined
        ) {
            Toast("error", respData?.data?.data);
        }
        return respData;
    } catch (e) {
        console.log("getuserwalletdetails_err", e);
    }
};


export const getalladminactivitydata = async (username) => {
    try {
        let respData = await axios.get(`${BACKURL}/api/admin/activitydata/${username}`);
        console.log('respDarta_getalladminactivitydata', respData);
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data);
        } else if (
            respData?.data?.success == false &&
            respData?.data?.data != undefined
        ) {
            Toast("error", respData?.data?.data);
        }

        return respData;
    } catch (e) {
        
    }
}


export const updateadminactivitydata = async (data) => {
    try {

        let respData = await axios.post(
            BACKURL + "/api/admin/updatenotification",
            data
        );
        if (respData?.data?.success == true && respData?.data?.data != undefined) {
            Toast("success", respData?.data?.data);
        } else if (
            respData?.data?.success == false &&
            respData?.data?.data != undefined
        ) {
            Toast("error", respData?.data?.data);
        }
        console.log('noti respondata', respData)
        return respData;
    } catch (e) {
        console.log("getuserwalletdetails_err", e);
    }
};


export const getallfeedbackdatahooks = async () => {
    try {
      let respData = await axios.post(BACKURL + "/api/User/getAllFeedBacks");
      if (respData?.data?.status == true ) {
        return respData;
      } 
    } catch (e) {
      console.log("getallfeedbackdatahooks_err", e)
    }
  }

export const copyUrl = async (message) => {
    toast.success(message, {
        position: position.position,
        style: style,
        iconTheme: iconTheme,
    });
    await sleep(1000);
}



