import React from "react";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="d-flex justify-content-center py-2 w-100">
          <span className="text-center text-sm-left d-block d-sm-inline-block">
            ©2023 <a href='#' target="_blank">COINPAYMATE,</a> Inc. All Rights Reserved
          </span>
        </div>
      </div>
    </footer>
  );
}
