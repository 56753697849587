import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { BiSolidUserCircle } from "react-icons/bi";
import { GiHamburgerMenu } from "react-icons/gi";
import { getadmindetailsbytoken, getalladminactivitydata, updateadminactivitydata } from "../../hooks/UseAdmin";
import { useLocation } from "react-router-dom";

export default function Navbar() {

let location = useLocation()
  
  const [adminname, setAdminName] = useState("")
  const [pathName, setPathName] = useState("")
  const [adminactivitydata, setAdminActivityData] = useState([]);
  const [status, setStatus] = useState(false)
  console.log('pathdataaaaaaaaaa', pathName, adminactivitydata)
  const AdminLogout = async () => {
    try {
      console.log("onclick now")
      sessionStorage.removeItem('accessToken')
      window.location.href = "/login"
    } catch (e) {
      console.log("AdminLogout_err", e)
    }
  }

  useEffect(() => {
    async function fetchData() {
      let accessToken = sessionStorage.getItem('accessToken')
      let res = await getadmindetailsbytoken(accessToken);
      let data = res?.data?.record;
      console.log('admin data respond', data)
      setAdminName(data?.username)
    }
    fetchData()
  }, []);

  useEffect(() => {
    async function fetchData() {
      console.log('amindatamadata', adminname)
      if(adminname) {
        let res = await getalladminactivitydata(adminname);
        console.log('enter this useeffect', res);
        if(res?.data?.success == true) {
          if(res?.data?.record?.length > 0) {
            console.log('res?.datasdfasdf', res?.data?.record, res?.data?.record.sort((a,b) => Number(a.Time) - Number(b.Time)))
            let record = res?.data?.record;
            let sortdata = record.sort((a, b) => new Date(b.Time) - new Date(a.Time));
            let count = 0;
            console.log('sortdata?.length', sortdata, sortdata?.length, count)
            if (sortdata?.length > 0) {
              let unseendata = []
              for (let i = 0; i < sortdata?.length; i++) {
                if (sortdata[i]['Status'] == "unseen") {
                  if(count < 3) {
                    unseendata.push(sortdata[i]);
                    count ++
                  }
                }
              }
              setAdminActivityData(unseendata)
            }
   
          }
        }
      }
    }

    fetchData()
  }, [adminname, status])


  useEffect(() => {
    let path  = location?.pathname;
    console.log("sadfasdfadf", path)

    setPathName(path)
  }, [location])

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
;
  console.log("pathnsdafasdfsame", pathName, pathName?.split("/")[1])


  const MarkasRead = async () => {
    try {
      let reqData = {
        'filter': "update",
        arr: adminactivitydata
      };
      console.log('req data', reqData)
      const res = await updateadminactivitydata(reqData);
      console.log('markallread', res);
      if(res?.data?.success == true) {
setStatus(!status)
      }
    } catch (e) {
      console.log('MarkasRead_err', e)
    }
  }

  return (
    <nav className={((pathName == "/login" || pathName?.split("/")[1]?.includes('reset-password')) || pathName == "/forgot-password"  )? "sidebarnone" : "navbar p-0 fixed-top d-flex flex-row"}>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/dashboard">
          <img src={require("../../assets/images/HeaderLogo.png")} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="mdi mdi-menu"></span>
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <Dropdown alignRight as="li" className="nav-item">
            <Dropdown.Toggle
              as="a"
              className="nav-link count-indicator cursor-pointer"
            >
              <i className="mdi mdi-bell"></i>
              <span className="count bg-danger"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list navdropnotfymenu">
              <h5 className="notifications_drop_header_txt mb-0 d-flex justify-content-between">
                <Trans>Notifications</Trans>
                <Link onClick={() => { MarkasRead()}}>Mark as read</Link>
              </h5>
              <Dropdown.Divider />

              {adminactivitydata && adminactivitydata?.length > 0 && adminactivitydata?.map((val, ind) => {
                {console.log("index data", ind, val?.Status, ind > 3)}
                if(val?.Status == "unseen" && ind < 3) {
                  return (
                    <>
                      <Dropdown.Item
                        className="dropdown-item preview-item"
                      >
                        <div className="preview-item-content">
                          <h6 className="preview-subject mb-1">
                            <Trans>{val?.action}</Trans>
                          </h6>
                          <p className="text-muted ellipsis mb-0">
                            <Trans>{val?.message}</Trans>
                          </p>
                        </div>
                      </Dropdown.Item>
                      <Dropdown.Divider />
  
                    </>
                  )
                }
              })
              }
              <Link to="/notifications">
                <p className="p-3 mb-0 text-center">
                  <Trans>See all notifications</Trans>
                </p>
              </Link>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown alignRight as="li" className="nav-item">
            <Dropdown.Toggle
              as="a"
              className="nav-link cursor-pointer no-caret"
            >
              <div className="navbar-profile">
                <img
                  className="img-xs rounded-circle"
                  src={require("../../assets/images/faces/face15.png")}
                  alt="profile"
                />
                <p className="mb-0 d-none d-sm-block navbar-profile-name">
                  <Trans>
                    {adminname}
                  </Trans>
                </p>
                <i className="mdi mdi-menu-down d-none d-sm-block"></i>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
              <h6 className="p-3 mb-0">
                <Trans>Profile</Trans>
              </h6>
              <Dropdown.Item className="user_profile_drop">
                <Link
                  to="/my-profile"
                  className="d-flex align-items-center text-decoration-none preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon rounded-circle">
                      {/* <i className="mdi mdi-profile text-success"></i> */}
                      <BiSolidUserCircle className="text-success" size={18} />
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>My Profile</Trans>
                    </p>
                  </div>
                </Link>
              </Dropdown.Item>
              {/* <Dropdown.Item className="user_profile_drop">
                <Link
                  to="/edit-settings"
                  className="d-flex align-items-center text-decoration-none preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon rounded-circle">
                      <i className="mdi mdi-settings text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      <Trans>Settings</Trans>
                    </p>
                  </div>
                </Link>
              </Dropdown.Item> */}
              <Dropdown.Item className="user_profile_drop">
                <Link
                  to="/login"
                  className="d-flex align-items-center text-decoration-none preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon rounded-circle">
                      <i className="mdi mdi-logout text-danger"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject mb-1">
                      {/* <button onClick={() => {AdminLogout()}}>Log Out</button> */}
                      <a onClick={() => { AdminLogout() }}>Log Out</a>
                    </p>
                  </div>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          {/* <span className="mdi mdi-format-line-spacing"></span> */}
          <GiHamburgerMenu />
        </button>
      </div>
    </nav>
  );
}
